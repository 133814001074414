<template>
  <div class="formtabs">
    <ul>
      <li v-for="(item, index) in tabs" :key="index" :class="{passed:current>=item.id, passgr:current>=item.id+1}"  v-show="hide!=item.id">
        <div class="noselect">
          <img :src="current>item.id?item.icon1:item.icon2" alt />
          <div class="text">{{item.txt}}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsPaket",

  props : ['current', 'hide'],

  data() {
    return {

      tabs: [
        // { id: 1, txt: "İzin bilgileri", icon1:"@/assets/img/ico_tick.svg", icon2:"@/assets/img/icons/P1.svg"},
        { id: 2, txt: "Paket seçimi", icon1:"@/assets/img/ico_tick.svg", icon2:"@/assets/img/icons/P2.svg" },
        { id: 3, txt: "İletişim adresi", icon1:"@/assets/img/ico_tick.svg", icon2:"@/assets/img/icons/P3.svg" },
        { id: 4, txt: "Ödeme", icon1:"@/assets/img/ico_tick.svg", icon2:"@/assets/img/icons/P4.svg" },
        // { id: 5, txt: "Sonuç" }
      ]
    };
  }

};
</script>


<style scoped>

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

</style>
