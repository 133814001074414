<template>
  <div class="formsubtabs">
    <ul>
      <li v-for="(item, index) in tabs" :key="index" :class="{passed:current>=item.id, passgr:current>=item.id+1}" v-show="hide!=item.id">
        <div class="noselect">
          <img :src="current>item.id?item.icon1:item.icon2" alt />
          <div class="text">{{item.txt}}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name : "TabsGirisBasvuruSub",

  props : ['current', 'hide'],

  data() {
    return {

      tabs: [
        { id: 1, txt: 'E-posta Doğrulama',  icon1:require('@/assets/img/ico_tab_gr.svg'), icon2:require('@/assets/img/ico_tab.svg') },
        { id: 2, txt: 'Cep Telefonu Doğrulama',          icon1:require('@/assets/img/ico_tab_gr.svg'), icon2:require('@/assets/img/ico_tab.svg') },
        // { id: 3, txt: 'Başvuru Şekli Belirleme',   icon1:require('@/assets/img/ico_tab_gr.svg'), icon2:require('@/assets/img/ico_tab.svg') },
        // { id: 4, txt: 'Belge Yükleme',      icon1:require('@/assets/img/ico_tab_gr.svg'), icon2:require('@/assets/img/ico_tab.svg') },
        // { id: 5, txt: 'Onaylama',           icon1:require('@/assets/img/ico_tab_gr.svg'), icon2:require('@/assets/img/ico_tab.svg') },
      ]
    };
  }

};
</script>

<style scoped>

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

</style>
