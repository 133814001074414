<template>
  <div class="formtabs">
    <ul>
      <li v-for="(item, index) in tabs" :key="index" :class="{passed:current>=item.id, passgr:current>=item.id+1}" v-show="hide!=item.id">
        <div class="noselect">
          <img :src="current>item.id?item.icon1:item.icon2" alt />
          <div class="text">{{item.txt}}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name : "TabsBasvuru",

  props : ['current', 'hide'],

  data() {
    return {

      tabs: [
        { id: 1, txt: 'Başvuru', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F1.svg')},
        { id: 2, txt: 'İnceleme', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F2.svg') },
        { id: 3, txt: 'Bilgi Girişi', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F4.svg') },
        { id: 4, txt: 'Onaylama', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F3.svg') },
        // { id: 5, txt: 'Onaylama', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F5.svg') },
        // { id: 6, txt: 'Sonuç', icon1:require('@/assets/img/ico_tick.svg'), icon2:require('@/assets/img/icons/F6.svg') }
      ]
    };
  }

};
</script>

<style scoped>

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

</style>
