import { render, staticRenderFns } from "./TabsEimzaBasvuruSub.vue?vue&type=template&id=5e828e5d&scoped=true&"
import script from "./TabsEimzaBasvuruSub.vue?vue&type=script&lang=js&"
export * from "./TabsEimzaBasvuruSub.vue?vue&type=script&lang=js&"
import style0 from "./TabsEimzaBasvuruSub.vue?vue&type=style&index=0&id=5e828e5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e828e5d",
  null
  
)

export default component.exports